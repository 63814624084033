import '../../sass/icons.scss';

const IconGame=()=>{
    return(
        <svg version="1.1" id="Isolation_Mode" xmlns="http://www.w3.org/2000/svg" x="0px"
            y="0px" viewBox="0 0 512 512" style={{"enableBackground":"new 0 0 512 512"}}>
        <g>
            <path className="game_st0" d="M495.5,300.2l-19.8-107.9c-6.4-49.3-48.6-86.4-98.3-86.4H134.6c-49.7,0-91.9,37.1-98.3,86.4L16.5,300.2
                C6.6,353.8,47.1,403.9,101.3,406c51.7,2.1,93.2-40.1,93.3-90.5c0-0.2-5.8-0.1,122.7-0.1c0,50.2,41.5,92.6,93.3,90.6
                C464.7,403.9,505.4,354,495.5,300.2z M442.3,366.6c-23.3,16.4-55.5,15-77.3-5.9c-12.3-11.8-19.3-28.4-19.3-45.4
                c0-15.2-12.5-28.1-28.3-28.1H194.6c-15.8,0-28.3,12.9-28.3,28.1c0,34.5-28.4,63.9-63.9,62.4c-32.5-1.3-58.1-27.9-59.1-59.8
                c-0.3-8-0.5-3.9,21-120.9c0-0.3,0.1-0.5,0.1-0.8c4.4-35.4,34.6-62,70.3-62h242.7c35.6,0,65.8,26.7,70.3,62c0.1,0.6-1.3-7.1,20,109
                C472.2,329.8,461.1,353.4,442.3,366.6L442.3,366.6z"/>
            <path className="game_st0" d="M188,204.1h-23.6v-23.6c0-7.8-6.3-14.2-14.2-14.2s-14.2,6.3-14.2,14.2v23.6h-23.6c-7.8,0-14.2,6.3-14.2,14.2
                s6.3,14.2,14.2,14.2h23.6V256c0,7.8,6.3,14.2,14.2,14.2s14.2-6.3,14.2-14.2v-23.6H188c7.8,0,14.2-6.3,14.2-14.2
                S195.8,204.1,188,204.1z"/>
            <path className="game_st0" d="M375.6,177.7c-1.8-8.4-10-13.2-18-10.8c-8.2,2.7-11.5,10.8-9.4,17.7c2.6,8.1,10.7,11.6,17.7,9.4
                C373.2,191.7,376.9,184.7,375.6,177.7L375.6,177.7z"/>
            <path className="game_st0" d="M399.5,204.1c-7.8,0-14.2,6.3-14.2,14.2v0.9c0,7.8,6.3,14.2,14.2,14.2s14.2-6.3,14.2-14.2v-0.9
                C413.7,210.4,407.3,204.1,399.5,204.1z"/>
            <path className="game_st0" d="M324,204.1c-7.8,0-14.2,6.3-14.2,14.2v0.9c0,7.8,6.3,14.2,14.2,14.2s14.2-6.3,14.2-14.2v-0.9
                C338.1,210.4,331.8,204.1,324,204.1z"/>
            <path className="game_st0" d="M375.6,253.2c-1.8-8.4-10-13.2-18-10.8c-8.1,2.7-11.5,10.7-9.4,17.7c2.7,8.3,10.9,11.5,17.7,9.4
                C373.2,267.2,376.9,260.3,375.6,253.2L375.6,253.2z"/>
        </g>
        </svg>
    )
}

export default IconGame;