import './view.scss';
import Cloud from '../../../assets/ghost_float/cloud.png'
import { useEffect } from 'react';

const GameView=()=>{

    useEffect(()=>{
            const home_banner = document.querySelector("body");
            const cloud1 = document.querySelector(".cloud1");
            const cloud2 = document.querySelector(".cloud2");
            const cloud3 = document.querySelector(".cloud3");
            const banner_bg = document.querySelector("#game_view");
            home_banner.addEventListener("mousemove", (e) => {
                cloud1.style.transform = `translate(${(e.pageX/100)}px,${(e.pageY/200)}px)`;
                cloud2.style.transform = `translate(${(e.pageX/150)}px,${(e.pageY/300)}px)`;
                cloud3.style.transform = `translate(${(e.pageX/200)}px,${(e.pageY/400)}px)`;
                banner_bg.style.backgroundPositionX=-e.pageX/200 + "px"
            });
        
    },[]);

    return(
        <div id="game_view">
            <div className="cloud1"><img src={Cloud} alt="" srcSet=""/></div>
            <div className="cloud2"><img src={Cloud} alt="" srcSet=""/></div>
            <div className="cloud3"><img src={Cloud} alt="" srcSet=""/></div>
        </div>
    )
}

export default GameView;