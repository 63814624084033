import './App.scss';

import Header from './components/view/header';
import HomeBanner from './components/view/home-banner';
import Portfolio from './components/view/portfolio';
import Parallax from './components/view/parallax';
import WhatWeDo from './components/view/what-we-do';
import Footer from './components/view/footer';

function App() {
  return (
    <div className="App wrapper">
        <Header/>
        <Parallax/>
        <HomeBanner/>
        <Portfolio/>
        <WhatWeDo/>
        <Footer/>
    </div>
  );
}

export default App;
