import '../../sass/icons.scss';

const SocialBH=()=>{ 
    return(
        <a href="./">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 120 120" style={{"enableBackground":"new 0 0 120 120"}}>
                <path className="bh_st0" d="M36.9,25.5c11.6,0,21.6,3.3,21.6,16.8c0,6.8-3.2,11.2-9.2,14.1c8.3,2.4,12.4,8.6,12.4,17.1
                    c0,13.8-11.5,19.7-23.8,19.7H5V25.5H36.9z M35,52.9c5.3,0,9.1-2.3,9.1-8.1c0-6.2-4.8-7.7-10.2-7.7h-14v15.8H35z M35.8,81.7
                    c6.1,0,11-2.1,11-9c0-6.9-4.1-9.7-10.7-9.7H19.9v18.6H35.8z M102.2,78h11.9c-3.7,11.2-11.2,16.5-22.9,16.5
                    c-15.5,0-25.1-10.5-25.1-25.7c0-14.7,10.2-25.9,25.1-25.9c15.4,0,23.8,12.1,23.8,26.6c0,0.9-0.1,1.7-0.1,2.5H79.6
                    c0,7.8,4.1,12.5,12,12.5C95.7,84.4,100.9,82.2,102.2,78z M76.8,30.1v6.7h27.4v-6.7H76.8z M79.6,63.4h21.9c-0.6-6.5-4-10.5-10.7-10.5
                    C84.3,53,80,57,79.6,63.4z"/>
            </svg>
        </a>
    )
}

export default SocialBH;