import './logo.css';
const ApexLogo=()=>{

    
    return(
    <svg className='apexLogo' version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox=" 0 0 1373.4 693.1" style={{"enableBackground":"new 0 0 512 512"}}>

<g>
	<g className="st0">
		<path className="st1" d="M1056.2,371.1l2.7,2.7c-9.2,10-22.9,15.9-38.6,15.9c-28,0-48.8-21.2-48.8-50.8c0-29.4,20.8-50.6,48.8-50.6
			c28.2,0,47.1,21.2,47.1,50.6h-92c0,27.6,18.8,46.9,44.9,46.9C1035,385.9,1047.7,380.6,1056.2,371.1z M975.6,335.1h87.8
			c-1.8-25.3-18-42.7-43.1-42.7C995.6,292.3,977.4,309.8,975.6,335.1z"/>
		<path className="st1" d="M1143.6,288.4h4.7l-36.3,48.8l39,52.5h-4.9l-36.7-49.2l-36.5,49.2h-4.9l39-52.5l-36.1-48.8h4.7l33.9,45.7
			L1143.6,288.4z"/>
		<path className="st1" d="M1270.1,339.2c0,29.2-20.8,50.6-49.2,50.6c-21.2,0-38-11.8-45.3-29.8v67.8h-3.9V288.4h3.9v29.8
			c7.3-18,24.1-29.8,45.3-29.8C1249.3,288.4,1270.1,309.8,1270.1,339.2z M1266.2,339.2c0-27.1-19-46.9-45.3-46.9
			c-26.3,0-45.3,19.8-45.3,46.9c0,27.1,19,46.7,45.3,46.7C1247.2,385.9,1266.2,366.2,1266.2,339.2z"/>
	</g>
	<g>
		<g>
			<path className="st1" d="M294.5,432.9h-52.1v-18.8h-0.6c-8.8,17-30.5,24.8-50.9,24.8c-55.3,0-87.6-43.3-87.6-93.3
				c0-57.2,41.1-93,87.6-93c28.6,0,44.6,13.8,50.9,24.5h0.6v-18.5h52.1V432.9z M198.7,390.1c32.7,0,43.7-27.6,43.7-44.6
				c0-21-14.4-44.3-44-44.3c-29.8,0-43,25.1-43,44.9C155.4,362.5,167.3,390.1,198.7,390.1z"/>
			<path className="st1" d="M306.2,258.6h49v19.2h0.3h0.6c10.4-13.2,27.6-25.1,54.3-25.1c61.9,0,85.1,54.3,85.1,94.8
				c0,50.2-35.2,91.4-84.8,91.4c-31.4,0-46.2-15.1-52.4-21.7V491h-52.1L306.2,258.6L306.2,258.6z M443.4,345.9
				c0-20.7-15.1-44.6-43.7-44.6S356,325.2,356,345.9c0,22,16.3,44.3,43.7,44.3C427.1,390.1,443.4,367.8,443.4,345.9z"/>
			<path className="st1" d="M677.3,378.2c-8.5,19.5-20.7,34.5-35.5,44.6c-14.4,10.4-31.7,15.7-49.6,15.7c-49.9,0-92.3-40.5-92.3-92.3
				c0-48.7,38.3-93.6,91.4-93.6c53.1,0,92,42.1,92,95.2c0,6.9-0.6,9.7-1.3,13.8H551.4c3.1,20.7,20.4,33,40.8,33
				c16,0,24.5-7.2,31.7-16.3h53.4V378.2z M631.2,326.4c-2.2-10.4-12.6-29.8-39.6-29.8s-37.4,19.5-39.6,29.8H631.2z"/>
		</g>
	</g>
	<g>
		
			<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="971.4068" y1="670.4262" x2="971.4068" y2="47.1397" gradientTransform="matrix(1 0 0 -1 0 691.2244)">
			<stop  offset="4.517100e-03" style={{"stopColor":"#049699"}}/>
			<stop  offset="0.4826" style={{"stopColor":"#47B06C"}}/>
			<stop  offset="0.8876" style={{"stopColor":"#6FBA6B"}}/>
			<stop  offset="1" style={{"stopColor":"#6FBA6B"}}/>
		</linearGradient>
		<polygon className="st2" points="1144.3,63.5 1077.9,63.5 798.5,343 956.6,500.9 1023.1,500.9 865.3,343.2 		"/>
		
			<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="622.0068" y1="670.4262" x2="622.0068" y2="47.1397" gradientTransform="matrix(1 0 0 -1 0 691.2244)">
			<stop  offset="4.517100e-03" style={{"stopColor":"#049699"}}/>
			<stop  offset="0.4826" style={{"stopColor":"#47B06C"}}/>
			<stop  offset="0.8876" style={{"stopColor":"#6FBA6B"}}/>
			<stop  offset="1" style={{"stopColor":"#6FBA6B"}}/>
		</linearGradient>
		<polygon className="st3" points="647.7,192.5 582.2,193.3 732,343.1 445.5,629.6 511.9,629.6 798.5,343 		"/>
		<polygon className="st4" points="865.3,343.2 1077.9,63.5 1144.3,63.5 		"/>
		<polygon className="st4" points="732,343.1 511.9,629.6 445.5,629.6 		"/>
		<polygon className="st4" points="798.5,343 1023.1,500.9 956.6,500.9 		"/>
		<polygon className="st4" points="798.5,343 582.2,193.3 647.7,192.5 		"/>
	</g>
	<g>
		<polygon className="st1" points="819.8,441.1 886.8,441.1 798.5,353 672.5,478.9 739,478.9 798.3,419.6 		"/>
		<polygon className="st1" points="853.4,211.7 798.6,266.4 785.5,253.3 718.7,253.3 798.5,333 919.8,211.7 		"/>
		<polygon className="st5" points="798.5,333 883.4,211.7 919.8,211.7 		"/>
		<polygon className="st5" points="798.5,353 709,478.9 672.5,478.9 		"/>
		<polygon className="st5" points="798.5,353 886.8,441.1 849.8,441.1 		"/>
		<polygon className="st5" points="798.5,333 718.7,253.3 755.5,253.3 		"/>
	</g>
</g>
</svg>


)
}

export default ApexLogo;