import '../../sass/footer.scss';

import SocialBH from '../icons/behance';
import SocialDB from '../icons/dribble';
import SocialFB from '../icons/facebook';
import SocialIN from '../icons/insta';
import ContactForm from '../partial/form';
const Footer=()=>{
    return(
        <footer id="contactUs">
            <div className="content-container">
                <div className="sec1">
                    <div className="email_info">
                        <div className="footer_head">Lets talk about<br/>our next project</div>
                        <div className="head">Drop us an email</div>
                        <div className="email">hello@apexexp.in</div>
                    </div>
                    <div className="social_icons">
                        <SocialFB/>
                        <SocialIN/>
                        <SocialDB/>
                        <SocialBH/>
                    </div>
                </div>
                <div className="sec2">
                    <div className="form_head">Send us a message</div>
                    <ContactForm/>
                </div>
            </div>
        </footer>
    )
}

export default Footer;