import '../../sass/portfolio.scss';
import projectData from '../../data/data';
import { useEffect,useState } from 'react';
import ApexCarosel from '../partial/carousel';



const Portfolio=()=>{
    const onNavClick=(category)=>{    
        const Navs=document.querySelectorAll(`.portfolio_nav .nav_btn`);
        Navs.forEach((nav)=>{
            if(nav.id===category){
                nav.classList.add("active");
            }else{
                nav.classList.remove("active");
            }
            
        });
        const newPortfolio= portfolio.filter((item)=>{
            return item.category===category||category==="all";
        });
        setPortfolio(newPortfolio);
    }

    const [portfolio,setPortfolio]=useState(projectData);
    useEffect(()=>{
        let navOpts=document.querySelectorAll(".portfolio_nav .nav_btn");
        navOpts.forEach((navOpt)=>{
            navOpt.addEventListener("click",()=>{onNavClick(navOpt.id)})
        })
    })
    return(
        <section id="portfolio">
            <div className="content-container">
                <div className="header">
                    <div className="title">Portfolio</div>
                    <div className="portfolio_nav">
                        <div id="all" className="nav_btn active">ALL</div>
                        <div id="games" className="nav_btn">GAMES</div>
                        <div id="playablead" className="nav_btn">PLAYABLE ADS</div>
                        <div id="uiux" className="nav_btn">UI/UX</div>
                        <div id="website" className="nav_btn">WEBSITE</div>
                    </div>
                </div>
                <div className="portfolio_slider" >
                    <ApexCarosel portfolio={portfolio} />
                </div>
            </div>
        </section>
    )

}
export default Portfolio;