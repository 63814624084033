import '../../sass/home-banner.scss';
import SocialBH from '../icons/behance';
import SocialDB from '../icons/dribble';
import SocialFB from '../icons/facebook';
import SocialIN from '../icons/insta';
import BannerVideo from '../partial/banner_video';
import ProductDetails from '../partial/product_details';
const HomeBanner=()=>{
    return(
        <section id='home_banner'>
            <div className="content-container">
                <div className="sec1"><ProductDetails/></div>
                <div className="sec2"><BannerVideo/></div>
                <div className="social_icons">
                    <SocialFB/>
                    <SocialIN/>
                    <SocialDB/>
                    <SocialBH/>
                </div>
            </div>
            
        </section>
    )
}

export default HomeBanner;