const ProductDetails=()=>{
    return(
        <div className="product_details">
            <div className="category">MINI GAMES</div>
            <div className="title">Ghost Float</div>
            <div className="info">Tap to avoid obstacles. collect boosters and score as high as you can.</div>
            <div className="product_buttons">
                <div id="btn_playNow">PLAY NOW</div>
                <div id="btn_contact">GET IN TOUCH</div>
            </div>
        </div>
    )
}

export default ProductDetails;