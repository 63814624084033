const projectData=[
    {
        "title":"Ghost Float",
        "category":"uiux",
        "categoryLabel":"UI/UX",
        "thumbnail_url":"projects/Thumbnail_ghost_float.png",
        "project_url":"http://www.apexexp.in/Games/GhostFloat/"
    },
    {
        "title":"Ghost Float",
        "category":"games",
        "categoryLabel":"Games",
        "thumbnail_url":"projects/Thumbnail_ghost_float.png",
        "project_url":"http://www.apexexp.in/Games/GhostFloat/"
    },
    {
        "title":"Link Four",
        "category":"playablead",
        "categoryLabel":"Playable AD",
        "thumbnail_url":"projects/Thumbnail_link_four.png",
        "project_url":"http://www.apexexp.in/Games/GhostFloat/"
    },{
        "title":"Ghost Float",
        "category":"games",
        "categoryLabel":"Games",
        "thumbnail_url":"projects/Thumbnail_ghost_float.png",
        "project_url":"http://www.apexexp.in/Games/GhostFloat/"
    },
    {
        "title":"Link Four",
        "category":"playablead",
        "categoryLabel":"Playable AD",
        "thumbnail_url":"projects/Thumbnail_link_four.png",
        "project_url":"http://www.apexexp.in/Games/GhostFloat/"
    },{
        "title":"Ghost Float",
        "category":"games",
        "categoryLabel":"Games",
        "thumbnail_url":"projects/Thumbnail_ghost_float.png",
        "project_url":"http://www.apexexp.in/Games/GhostFloat/"
    },
    {
        "title":"Link Four",
        "category":"playablead",
        "categoryLabel":"Playable AD",
        "thumbnail_url":"projects/Thumbnail_link_four.png",
        "project_url":"http://www.apexexp.in/Games/GhostFloat/"
    },{
        "title":"Ghost Float",
        "category":"games",
        "categoryLabel":"Games",
        "thumbnail_url":"projects/Thumbnail_ghost_float.png",
        "project_url":"http://www.apexexp.in/Games/GhostFloat/"
    },
    {
        "title":"Link Four",
        "category":"games",
        "categoryLabel":"Games",
        "thumbnail_url":"projects/Thumbnail_link_four.png",
        "project_url":"http://www.apexexp.in/Games/GhostFloat/"
    },{
        "title":"Ghost Float",
        "category":"website",
        "categoryLabel":"Website",
        "thumbnail_url":"projects/Thumbnail_ghost_float.png",
        "project_url":"http://www.apexexp.in/Games/GhostFloat/"
    },
    {
        "title":"Link Four",
        "category":"website",
        "categoryLabel":"Website",
        "thumbnail_url":"projects/Thumbnail_link_four.png",
        "project_url":"http://www.apexexp.in/Games/GhostFloat/"
    }
]

export default projectData;