import '../../sass/icons.scss';

const SocialFB=()=>{ 
    return(
        <a href="./">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 120 120" style={{"enableBackground":"new 0 0 120 120"}}>
                <path className="fb_st0" d="M81,33h-7.6c-6,0-7.1,2.9-7.1,7v9.2h14.2l-1.9,14.4H66.3v36.9H51.4V63.5H39V49.2h12.4V38.6
                    c0-12.3,7.5-19,18.5-19c5.3,0,9.8,0.4,11.1,0.6V33z"/>
            </svg>
        </a>
    )
}

export default SocialFB;