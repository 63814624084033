import MobileFrame from '../../assets/banner-mobile-outline.png';
import videoUrl from '../../assets/ghost_float/game.mp4';
const BannerVideo=()=>{
    return (
        <div id="banner_video_sec">
            <img src={MobileFrame} alt="" srcSet="" className="videoFrame"/>
            <video src={videoUrl} className="banner_video"></video>
        </div>
    )
}

export default BannerVideo;