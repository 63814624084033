import ArrowRight from '../icons/arrow-right';
import WorkIcon from './work-icon';


const ServiceCard=({iconKey,title,info})=>{

    return(
        <article className="card">
            <div className="icon"><WorkIcon iconKey={iconKey} /></div>
            <div className="card_txt">
                <div className="title">{title}</div>
                <div className="info">{info}</div>
            </div>
            <div className="explore">
                Explore<span><ArrowRight/></span>
            </div>
        </article>
    )
}

export default ServiceCard;