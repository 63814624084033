import '../../sass/icons.scss';

const SocialDB=()=>{ 
    return(
        <a href="./">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 120 120" style={{"enableBackground":"new 0 0 120 120"}}>
                <path className="db_st0" d="M60,100c-22.1,0-40-17.9-40-40c0-22.1,17.9-40,40-40s40,17.9,40,40C100,82.1,82.1,100,60,100z M60.9,54.1
                    c-17.6,5.3-34.5,4.8-35,4.8c-0.1,0.4-0.1,0.7-0.1,1.1c0,8.8,3.3,16.8,8.8,22.8c9.3-16.6,27.8-22.6,27.8-22.6
                    c0.5-0.2,0.9-0.3,1.4-0.4C62.9,57.9,61.9,56,60.9,54.1z M45.4,29.1C35.9,33.6,28.8,42.4,26.6,53c0.9,0,15.2,0.2,31.6-4.2
                    C52.2,38.3,45.9,29.7,45.4,29.1z M66,65.5c-0.1,0-0.2,0.1-0.2,0.1c0,0-20.3,7.1-26.8,21.4c-0.3-0.3-0.8-0.6-0.8-0.6
                    c5.9,4.8,13.5,7.8,21.8,7.8c4.7,0,9.2-1,13.3-2.7C72.8,88.4,70.8,78,66,65.5z M51.7,26.9c0,0,0.1-0.1,0.1-0.1
                    C51.8,26.8,51.8,26.8,51.7,26.9z M60,25.8c-2.8,0-5.5,0.4-8.1,1c0.5,0.7,7,9.2,12.8,19.9c12.9-4.8,17.7-12.2,17.8-12.3
                    C76.6,29.1,68.6,25.8,60,25.8z M86.4,38.3c-0.1,0.1-5.6,8-19.1,13.5c0.8,1.6,1.6,3.3,2.3,4.9c0.3,0.6,0.5,1.2,0.7,1.8
                    c11.8-1.5,23.4,1,23.8,1.1C94.1,51.6,91.2,44.1,86.4,38.3z M72.4,63.9c4.5,12.3,6.3,22.4,6.7,24.4c7.7-5.2,13.1-13.4,14.6-22.9
                    C93,65.2,83.5,62.2,72.4,63.9z"/>
            </svg>
        </a>
    )
}

export default SocialDB;