import '../../sass/icons.scss';
const ArrowRight=()=>{
    return(
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 120 120" style={{"enableBackground":"new 0 0 120 120"}}>
        <polygon className="ar_right_st0" points="84.1,29.1 79.3,33.9 100.9,55.5 5.1,55.5 5.1,62.4 100.9,62.4 79.3,83.9 84.1,88.8 114,58.9 "/>
        </svg>
    )
}

export default ArrowRight;