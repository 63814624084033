import Carousel, { slidesToShowPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { useEffect } from 'react';

const ApexCarosel=({portfolio})=>{
    useEffect(()=>{
        setTimeout(()=>{
            const carouselCards=document.querySelectorAll(".carousel_card");
            carouselCards.forEach((carouselCard)=>{
                carouselCard.classList.remove("active");
                setTimeout(()=>{carouselCard.classList.add("active");},50);
                
            })
        },0)
    })
    return(
        <Carousel plugins={[
            'clickToChange',
            {
            resolve: slidesToShowPlugin,
            options: {
            numberOfSlides: 3
            }
            },
        ]} breakpoints={{
            640: {
            plugins: [
            {
                resolve: slidesToShowPlugin,
                options: {
                numberOfSlides: 1
                }
            },
            ]
            },
            900: {
            plugins: [
            {
                resolve: slidesToShowPlugin,
                options: {
                numberOfSlides: 3
                }
            },
            ]
            }
        }}>
        {
        portfolio.map((item,index)=>{
                return <div className="carousel_card" key={index}>
                    <img src={`./${item.thumbnail_url}`} alt=""/>
                    <div className="title">{item.title}</div>
                    <div className="category">{item.categoryLabel}</div>
                </div>
            })
        }
    </Carousel>
    )
}

export default ApexCarosel;
