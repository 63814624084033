import '../../sass/icons.scss';

const SocialIN=()=>{ 
    return(
        <a href="./">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 120 120" style={{"enableBackground":"new 0 0 120 120"}}>
                <path className="in_st0" d="M99.7,76.5c-0.3,6.4-1.8,12.1-6.5,16.8c-4.7,4.7-10.4,6.1-16.8,6.5C71,100,65.5,100,60,100s-11,0.1-16.5-0.3
                    c-6.4-0.3-12.1-1.8-16.8-6.5c-4.7-4.7-6.1-10.4-6.5-16.8C19.9,71,20,65.5,20,60c0-5.5-0.1-11,0.3-16.5c0.3-6.4,1.8-12.1,6.5-16.8
                    c4.7-4.7,10.4-6.1,16.8-6.5C49,20,54.5,20,60,20s11-0.1,16.5,0.3c6.4,0.3,12.1,1.8,16.8,6.5c4.7,4.7,6.1,10.4,6.5,16.8
                    c0.3,5.5,0.3,11,0.3,16.5C100,65.5,100.1,71,99.7,76.5z M36.4,28.8c-1.8,0.7-3.2,1.6-4.6,3c-1.4,1.4-2.3,2.8-3,4.6
                    c-2.1,5.3-1.6,17.8-1.6,23.6s-0.5,18.3,1.6,23.6c0.7,1.8,1.6,3.2,3,4.6c1.4,1.4,2.8,2.3,4.6,3c5.3,2.1,17.8,1.6,23.6,1.6
                    s18.3,0.5,23.6-1.6c1.8-0.7,3.2-1.6,4.6-3c1.4-1.4,2.3-2.8,3-4.6c2.1-5.3,1.6-17.8,1.6-23.6s0.5-18.3-1.6-23.6
                    c-0.7-1.8-1.6-3.2-3-4.6c-1.4-1.4-2.8-2.3-4.6-3c-5.3-2.1-17.8-1.6-23.6-1.6S41.7,26.7,36.4,28.8z M60,80.5
                    c-11.4,0-20.5-9.2-20.5-20.5c0-11.4,9.2-20.5,20.5-20.5S80.5,48.6,80.5,60C80.5,71.4,71.4,80.5,60,80.5z M60,46.7
                    c-7.3,0-13.3,6-13.3,13.3c0,7.3,6,13.3,13.3,13.3s13.3-6,13.3-13.3C73.3,52.7,67.3,46.7,60,46.7z M81.4,43.4c-2.7,0-4.8-2.1-4.8-4.8
                    c0-2.7,2.1-4.8,4.8-4.8c2.7,0,4.8,2.1,4.8,4.8C86.1,41.3,84,43.4,81.4,43.4z"/>
            </svg>
        </a>
    )
}

export default SocialIN;