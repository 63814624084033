import ServiceCard from "../partial/service-card";
import '../../sass/what-we-do.scss';


const WhatWeDo=()=>{
    const whatWeDo=[
        {"title":"Mini Games","info":"Build interactive games to engage audiences and enhance your brand recall.","icon":"work_icon_game"},
        {"title":"Playable Ads","info":"Give audiences an interactive preview of your app, product or experience.","icon":"work_icon_playable_ad"},
        {"title":"UI/UX Design","info":"Craft interactive experiences to optimise business goals and users needs.","icon":"work_icon_ui_ux"},
        {"title":"Website","info":"Build aesthetic and optimised websites to showcase your true potential.","icon":"work_icon_website"},
        {"title":"Release","info":"Create customizable illustrations with attractive designs that are made visually through high creativity.","icon":"work_icon_release"}
    ]
    return(
        <section id="services">
            <div className="content-container">
                <div className="cards">
                    <div className="header-card">
                        What<br/><span className='sub'>we do</span>
                    </div>
                    {
                        whatWeDo.map((whatWeDoObj)=>{
                            return <ServiceCard iconKey={whatWeDoObj.icon} title={whatWeDoObj.title} info={whatWeDoObj.info} />;
                        })
                    }
                </div>
            </div>
            
        </section>
    )
}

export default WhatWeDo;