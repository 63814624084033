import IconGame from "../icons/icon-game";
import IconPlayable from "../icons/icon-playable";
import IconRelease from "../icons/icon-release";
import IconUiUx from "../icons/icon-uiux";
import IconWebsite from "../icons/icon-website";

const WorkIcon=({iconKey})=>{
    switch(iconKey){
        case "work_icon_game":
            return <IconGame/>
        case "work_icon_playable_ad":
            return <IconPlayable/>
        case "work_icon_ui_ux":
            return <IconUiUx/>
        case "work_icon_website":
            return <IconWebsite/>
        case "work_icon_release":
            return <IconRelease/>
        default:
            return null;
    }
}
export default WorkIcon;