import '../../sass/icons.scss';

const IconWebsite=()=>{
    return(
        <svg version="1.1" id="Isolation_Mode" xmlns="http://www.w3.org/2000/svg" x="0px"
            y="0px" viewBox="0 0 512 512" style={{"enableBackground":"new 0 0 512 512"}}>
        <g>
            <g>
                <g>
                    <path class="website_st0" d="M256,103.9c-83.9,0-152.1,68.2-152.1,152.1S172.1,408.1,256,408.1S408.1,339.9,408.1,256
                        S339.9,103.9,256,103.9z M379.7,242.2h-54.8c-1.3-33.5-7.2-64.7-16.9-89.1c-1.8-4.5-3.7-8.7-5.8-12.6
                        C343.9,157.2,374.5,195.9,379.7,242.2L379.7,242.2z M132.3,269.8h54.8c1.3,33.5,7.2,64.7,16.9,89.1c1.8,4.5,3.7,8.7,5.8,12.6
                        C168.1,354.8,137.5,316.1,132.3,269.8z M204,153.1c-9.8,24.4-15.6,55.6-16.9,89.1h-54.8c5.1-46.3,35.8-85,77.5-101.7
                        C207.8,144.4,205.9,148.6,204,153.1z M282.3,348.6c-7.7,19.3-18,31.8-26.3,31.8c-8.2,0-18.5-12.5-26.3-31.8
                        c-8.5-21.3-13.7-48.9-14.9-78.8h82.4C296,299.8,290.8,327.4,282.3,348.6L282.3,348.6z M214.8,242.2c1.2-29.9,6.4-57.5,14.9-78.8
                        c7.7-19.3,18-31.8,26.3-31.8c8.2,0,18.5,12.5,26.3,31.8c8.5,21.3,13.7,48.9,14.9,78.8H214.8z M302.2,371.6c2-3.9,4-8.1,5.8-12.6
                        c9.8-24.4,15.6-55.6,16.9-89.1h54.8C374.5,316.1,343.9,354.8,302.2,371.6L302.2,371.6z M492,256c0-129.9-106.1-236-236-236
                        c-29,0-58.6,6.1-88.2,18c-7.1,2.9-10.5,10.9-7.6,18c2.9,7.1,10.9,10.5,18,7.6c26.2-10.6,52.4-16,77.8-16
                        c114.9,0,208.3,93.5,208.3,208.3c0,41.9-12.9,82.3-37.4,117.6c-14.7-5.5-32-2.3-43.8,9.5c-16.2,16.2-16.2,42.5,0,58.7
                        c16.2,16.2,42.5,16.2,58.7,0c13.6-13.6,15.8-34.5,6.4-50.4C476.9,351,492,304.4,492,256L492,256z M422.2,422.2
                        c-5.4,5.4-14.2,5.4-19.6,0c-5.4-5.4-5.4-14.2,0-19.6c5.4-5.4,14.2-5.4,19.6,0C427.6,408.1,427.6,416.8,422.2,422.2L422.2,422.2z
                        M333.8,448.3c-26.2,10.6-52.4,16-77.8,16c-114.9,0-208.3-93.5-208.3-208.3c0-41.9,12.9-82.3,37.4-117.6
                        c15,5.6,32.2,2.1,43.8-9.5c16.2-16.2,16.2-42.5,0-58.7s-42.5-16.2-58.7,0c-13.9,13.9-15.6,34.8-6.4,50.4
                        C35.1,161,20,207.6,20,256c0,129.9,106.1,236,236,236c29,0,58.6-6.1,88.2-18c7.1-2.9,10.5-10.9,7.6-18
                        C348.9,448.9,340.9,445.5,333.8,448.3L333.8,448.3z M89.8,89.8c5.4-5.4,14.2-5.4,19.6,0c5.4,5.4,5.4,14.2,0,19.6
                        c-5.4,5.4-14.2,5.4-19.6,0C84.4,103.9,84.4,95.2,89.8,89.8z"/>
                </g>
            </g>
        </g>
        </svg>
    )
}
export default IconWebsite;