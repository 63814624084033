import '../../sass/icons.scss';

const IconUiUx=()=>{
    return(
        <svg version="1.1" id="Isolation_Mode" xmlns="http://www.w3.org/2000/svg" x="0px"
            y="0px" viewBox="0 0 512 512" style={{"enableBackground":"new 0 0 512 512"}}>
        <g>
            <g>
                <path class="uiux_st0" d="M89.9,356.9c2.7,1.7,5.8,2.6,8.8,2.6c5.3,0,10.6-2.6,13.7-7.5c4.8-7.5,2.7-17.6-4.9-22.4
                    c-7.6-4.9-12.1-13.1-12.1-22.1v-0.8c0-9-7.3-16.2-16.2-16.2s-16.2,7.3-16.2,16.2v0.8C62.9,327.6,73,346.1,89.9,356.9L89.9,356.9z"
                    />
                <path class="uiux_st0" d="M79.1,260.2c9,0,16.2-7.3,16.2-16.2v-43.3c0-9-7.3-16.2-16.2-16.2s-16.2,7.3-16.2,16.2V244
                    C62.9,252.9,70.1,260.2,79.1,260.2z"/>
                <path class="uiux_st0" d="M351.9,251.8c-9,0-16.2,7.3-16.2,16.2v39.5c0,0.8,0,1.6-0.1,2.4c-0.8,8.9,5.8,16.8,14.7,17.6
                    c0.5,0,1,0.1,1.4,0.1c8.3,0,15.4-6.4,16.1-14.8c0.2-1.7,0.2-3.5,0.2-5.2V268C368.1,259.1,360.9,251.8,351.9,251.8L351.9,251.8z"/>
                <path class="uiux_st0" d="M79.1,154.1c9,0,16.2-7.3,16.2-16.2V98.5c0-0.8,0-1.6,0.1-2.4c0.8-8.9-5.8-16.8-14.7-17.6
                    c-8.9-0.8-16.8,5.8-17.6,14.7c-0.2,1.8-0.2,3.6-0.2,5.3v39.4C62.9,146.9,70.1,154.1,79.1,154.1z"/>
                <path class="uiux_st0" d="M249.9,350c0,9,7.3,16.2,16.2,16.2h43.4h0c9,0,16.2-7.3,16.2-16.2c0-9-7.3-16.2-16.2-16.2c0,0-0.1,0-0.2,0
                    h-43.2C257.2,333.8,249.9,341,249.9,350L249.9,350z"/>
                <path class="uiux_st0" d="M143.9,350c0,9,7.3,16.2,16.2,16.2h43.3c9,0,16.2-7.3,16.2-16.2c0-9-7.3-16.2-16.2-16.2h-43.3
                    C151.1,333.8,143.9,341,143.9,350z"/>
                <path class="uiux_st0" d="M351.9,145.8c-9,0-16.2,7.3-16.2,16.2v43.3c0,9,7.3,16.2,16.2,16.2s16.2-7.3,16.2-16.2V162
                    C368.1,153,360.9,145.8,351.9,145.8z"/>
                <path class="uiux_st0" d="M227.5,72.3h43.3c9,0,16.2-7.3,16.2-16.2s-7.3-16.2-16.2-16.2h-43.3c-9,0-16.2,7.3-16.2,16.2
                    S218.6,72.3,227.5,72.3z"/>
                <path class="uiux_st0" d="M323.6,76.4c7.6,4.9,12.1,13.1,12.1,22.1v0.7c0,9,7.3,16.2,16.2,16.2s16.2-7.3,16.2-16.2v-0.7
                    c0-20.1-10.1-38.6-27.1-49.5c-7.5-4.8-17.6-2.6-22.4,4.9C313.8,61.5,316,71.6,323.6,76.4z"/>
                <path class="uiux_st0" d="M164.8,39.8h-43.3c-9,0-16.2,7.3-16.2,16.2s7.3,16.2,16.3,16.2h43.2c9,0,16.2-7.3,16.2-16.2
                    S173.7,39.8,164.8,39.8z"/>
            </g>
            <g>
                <path class="uiux_st0" d="M170.9,462.9c2.7,1.7,5.8,2.6,8.8,2.6c5.3,0,10.6-2.6,13.7-7.5c4.8-7.5,2.7-17.6-4.9-22.4
                    c-7.6-4.9-12.1-13.1-12.1-22.1v-0.8c0-9-7.3-16.2-16.2-16.2c-9,0-16.2,7.3-16.2,16.2v0.8C143.9,433.5,154,452,170.9,462.9
                    L170.9,462.9z"/>
                <path class="uiux_st0" d="M160.1,366.1c9,0,16.2-7.3,16.2-16.2v-43.3c0-9-7.3-16.2-16.2-16.2s-16.2,7.3-16.2,16.2v43.3
                    C143.9,358.9,151.1,366.1,160.1,366.1z"/>
                <path class="uiux_st0" d="M432.9,357.8c-9,0-16.2,7.3-16.2,16.2v39.5c0,0.8,0,1.6-0.1,2.4c-0.8,8.9,5.8,16.8,14.7,17.6
                    c0.5,0,1,0.1,1.4,0.1c8.3,0,15.4-6.4,16.1-14.8c0.2-1.7,0.2-3.5,0.2-5.2V374C449.1,365,441.9,357.8,432.9,357.8L432.9,357.8z"/>
                <path class="uiux_st0" d="M160.1,260.1c9,0,16.2-7.3,16.2-16.2v-39.4c0-0.8,0-1.6,0.1-2.4c0.8-8.9-5.8-16.8-14.7-17.6
                    c-8.9-0.8-16.8,5.8-17.6,14.7c-0.2,1.8-0.2,3.6-0.2,5.3v39.4C143.9,252.8,151.1,260.1,160.1,260.1z"/>
                <path class="uiux_st0" d="M330.9,455.9c0,9,7.3,16.2,16.2,16.2h43.4h0c9,0,16.2-7.3,16.2-16.2c0-9-7.3-16.2-16.2-16.2c0,0-0.1,0-0.2,0
                    h-43.2C338.2,439.7,330.9,447,330.9,455.9L330.9,455.9z"/>
                <path class="uiux_st0" d="M224.9,455.9c0,9,7.3,16.2,16.2,16.2h43.3c9,0,16.2-7.3,16.2-16.2c0-9-7.3-16.2-16.2-16.2h-43.3
                    C232.1,439.7,224.9,447,224.9,455.9z"/>
                <path class="uiux_st0" d="M432.9,251.7c-9,0-16.2,7.3-16.2,16.2v43.3c0,9,7.3,16.2,16.2,16.2s16.2-7.3,16.2-16.2v-43.3
                    C449.1,259,441.9,251.7,432.9,251.7z"/>
                <path class="uiux_st0" d="M308.5,178.2h43.3c9,0,16.2-7.3,16.2-16.2s-7.3-16.2-16.2-16.2h-43.3c-9,0-16.2,7.3-16.2,16.2
                    S299.6,178.2,308.5,178.2z"/>
                <path class="uiux_st0" d="M404.6,182.3c7.6,4.9,12.1,13.1,12.1,22.1v0.7c0,9,7.3,16.2,16.2,16.2s16.2-7.3,16.2-16.2v-0.7
                    c0-20.1-10.1-38.6-27.1-49.5c-7.5-4.8-17.6-2.6-22.4,4.9C394.8,167.5,397,177.5,404.6,182.3z"/>
                <path class="uiux_st0" d="M245.8,145.8h-43.3c-9,0-16.2,7.3-16.2,16.2s7.3,16.2,16.3,16.2h43.2c9,0,16.2-7.3,16.2-16.2
                    S254.7,145.8,245.8,145.8z"/>
            </g>
            <polygon class="uiux_st0" points="171.7,366.1 368,366.2 368,164.6 308.5,145.8 143.9,145.8 143.9,342.7 	"/>
        </g>
        </svg>
    )
}
export default IconUiUx;