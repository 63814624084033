import ApexLogo from '../icons/logo';
import '../../sass/header.scss';
import {Navbar,Nav} from 'react-bootstrap';
const Header=()=>{
    return(
        <header>
          <Navbar collapseOnSelect expand="md" style={{padding:"1.25rem 2rem"}}>
                <Navbar.Brand href="#home"><ApexLogo/></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link href="#">HOME</Nav.Link>
                        <Nav.Link href="#portfolio">PORTFOLIO</Nav.Link>
                        <Nav.Link href="#services">SERVICES</Nav.Link>
                        <Nav.Link href="#contactUs" className='btn-gradient'>CONTACT US</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </header>
    )
}

export default Header;