import '../../sass/icons.scss';

const IconPlayable=()=>{
    return(
        <svg version="1.1" id="Isolation_Mode" xmlns="http://www.w3.org/2000/svg" x="0px"
            y="0px" viewBox="0 0 512 512" style={{"enableBackground":"new 0 0 512 512"}}>
        <g>
            <path class="playable_st0" d="M325.7,426.3c12.2,0,22.2-10,22.2-22.2c0-12.2-10-22.2-22.2-22.2c-12.2,0-22.2,10-22.2,22.2
                C303.4,416.3,313.4,426.3,325.7,426.3z"/>
            <path class="playable_st0" d="M132,471.5h248c10.6,0,19.2-8.6,19.2-19.2V333.8c-5.8,2.9-12.3,4.5-19.2,4.5H132c-6.9,0-13.4-1.6-19.2-4.5
                v118.6C112.8,462.9,121.4,471.5,132,471.5z M325.7,348.5c30.6,0,55.5,24.9,55.5,55.5c0,30.6-24.9,55.5-55.5,55.5
                c-30.6,0-55.5-24.9-55.5-55.5C270.1,373.4,295,348.5,325.7,348.5z M150.2,387.4h17.3v-17.3c0-9.2,7.5-16.7,16.7-16.7
                c9.2,0,16.7,7.5,16.7,16.7v17.3h17.3c9.2,0,16.7,7.5,16.7,16.7c0,9.2-7.5,16.7-16.7,16.7h-17.3v17.3c0,9.2-7.5,16.7-16.7,16.7
                c-9.2,0-16.7-7.5-16.7-16.7v-17.3h-17.3c-9.2,0-16.7-7.5-16.7-16.7S141,387.4,150.2,387.4z"/>
            <path class="playable_st0" d="M380,40.5H132c-10.6,0-19.2,8.6-19.2,19.2v235.2c0,10.6,8.6,19.2,19.2,19.2h248c10.6,0,19.2-8.6,19.2-19.2
                V59.6C399.2,49.1,390.6,40.5,380,40.5z M296.3,180.2L237,214.5c-1.8,1-3.8,1.6-5.8,1.6s-4-0.5-5.8-1.6c-3.6-2.1-5.8-5.8-5.8-10V136
                c0-4.2,2.2-7.9,5.8-10c3.6-2.1,7.9-2.1,11.6,0l59.3,34.3c3.6,2.1,5.8,5.8,5.8,10C302.1,174.4,299.9,178.1,296.3,180.2z"/>
            <path class="playable_st1" d="M150.2,420.7h17.3v17.3c0,9.2,7.5,16.7,16.7,16.7c9.2,0,16.7-7.5,16.7-16.7v-17.3h17.3
                c9.2,0,16.7-7.5,16.7-16.7c0-9.2-7.5-16.7-16.7-16.7h-17.3v-17.3c0-9.2-7.5-16.7-16.7-16.7c-9.2,0-16.7,7.5-16.7,16.7v17.3h-17.3
                c-9.2,0-16.7,7.5-16.7,16.7S141,420.7,150.2,420.7z"/>
            <path class="playable_st1" d="M325.7,459.6c30.6,0,55.5-24.9,55.5-55.5c0-30.6-24.9-55.5-55.5-55.5c-30.6,0-55.5,24.9-55.5,55.5
                C270.1,434.7,295,459.6,325.7,459.6z M303.4,404.1c0-12.2,10-22.2,22.2-22.2c12.2,0,22.2,10,22.2,22.2c0,12.2-10,22.2-22.2,22.2
                C313.4,426.3,303.4,416.3,303.4,404.1z"/>
            <path class="playable_st1" d="M380,16.2H132c-24,0-43.5,19.5-43.5,43.5v235.2v157.6c0,24,19.5,43.5,43.5,43.5h248c24,0,43.5-19.5,43.5-43.5
                V294.8V59.6C423.5,35.7,404,16.2,380,16.2z M112.8,333.8c5.8,2.9,12.3,4.5,19.2,4.5h248c6.9,0,13.4-1.6,19.2-4.5v118.6
                c0,10.6-8.6,19.2-19.2,19.2H132c-10.6,0-19.2-8.6-19.2-19.2V333.8z M399.2,294.8c0,10.6-8.6,19.2-19.2,19.2H132
                c-10.6,0-19.2-8.6-19.2-19.2V59.6c0-10.6,8.6-19.2,19.2-19.2h248c10.6,0,19.2,8.6,19.2,19.2V294.8z"/>
            <path class="playable_st1" d="M296.3,160.2L237,126c-3.6-2.1-7.9-2.1-11.6,0c-3.6,2.1-5.8,5.8-5.8,10v68.5c0,4.2,2.2,7.9,5.8,10
                c1.8,1,3.8,1.6,5.8,1.6s4-0.5,5.8-1.6l59.3-34.3c3.6-2.1,5.8-5.8,5.8-10C302.1,166,299.9,162.3,296.3,160.2z"/>
        </g>
        </svg>
    )
}
export default IconPlayable;