const ContactForm=()=>{
    return(
        <form action="" id="contact-form">
            <div className="form-row">
                <input type="text" placeholder="Name" name="name" id="name" />
            </div>
            <div className="form-row">
                <input type="email" placeholder="Email" name="email" id="email" />
            </div>
            <div class="form-row">
                <select class="ui dropdown" id="service" name="service" >
                    <option value="Mini Games" class="googlefontlight">Mini Games</option>
                    <option value="Playable Ads" class="googlefontlight">Playable Ads</option>
                    <option value="UI/UX" class="googlefontlight">UI/UX</option>
                    <option value="Website" class="googlefontlight">Website</option>
                </select>
            </div>
            <div className="form-row">
                <input type="text" placeholder="Message" name="message" id="message" />
            </div>
            <div className="form-row">
                <button type="submit" className='btn-gradient' id="contactBtn">SEND</button>
            </div>
        </form>
    )
}

export default ContactForm;