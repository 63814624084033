
import GameView from '../banner-games-view/ghost_float/view';

const Parallax=()=>{
    return(
        <div className="parallax-container">
            <div className="parallax">    
                <GameView/>  
            </div>
        </div>
    )
}

export default Parallax;